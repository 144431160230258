import React from 'react';
import get from 'lodash.get';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import HeroSlider from 'components/HeroSlider';
import CustomLink from 'components/Link';

import { getYouTubeId, getPreviewUrl, getEmbedUrl } from 'helpers/youtube';
import { injectIntl, Link } from 'gatsby-plugin-react-intl';
import { formatHTML } from 'helpers/text';

import {
    Spacing,
    SharedStyles,
    Container,
    ProductStyle,
    ImageTextInfo,
    VideoBlock,
    StyleBlock,
    ImageTextGrid,
    ImageTextTile,
    ImageTextGridImage,
    ThrowbackPanel,
    SpotifyPanel,
    VideoRow,
    FlexImageText,
} from '../kenra-storybook/index';

const {
    StSectionInfo,
    StSectionMobileViewMore,
    StSectionTitle,
    StLinkMore,
    StTitle,
    StPageImageTitleBar,
    StBigTitle,
    StMediumTitle,
    StBigTitleGradient,
    StMediumTitleGradient,
    StSectionBody,
} = SharedStyles;

const RapunzelLanding = ({ intl, page }) => {
    return (
        <>
            <Spacing removeSpaceTop removeSpaceBottomMobile>
                <VideoBlock
                    renderVideo
                    imgUrl={get(page, 'videoBannerPreview.localFile.publicURL')}
                >
                    <video preload="metadata">
                        <source
                            src={get(page, 'videoBanner.localFile.publicURL')}
                            type="video/mp4"
                        />
                        {intl.formatMessage({
                            id: 'kenra-video-not-supported',
                        })}
                    </video>
                </VideoBlock>
            </Spacing>

            <Spacing>
                <StSectionInfo>
                    <Container>
                        <StSectionBody
                            style={{ maxWidth: '450px', margin: 'auto' }}
                        >
                            <img
                                src={get(page, 'logo.localFile.publicURL')}
                                alt=""
                            ></img>
                        </StSectionBody>
                        <StSectionBody
                            dangerouslySetInnerHTML={{
                                __html: formatHTML(JSON.parse(page.text.raw || '{}')),
                            }}
                        ></StSectionBody>
                    </Container>
                </StSectionInfo>
            </Spacing>
            <div
                style={{ backgroundColor: page.highlightColor, height: '10px' }}
            />
            <Spacing removeSpaceTop>
                <StSectionInfo
                    style={{
                        backgroundColor: page.backgroundColor,
                    }}
                >
                    <Spacing>
                        <Spacing removeSpaceTop>
                            <StTitle color={page.highlightColor}>
                                <h2>{page.section2Title}</h2>
                            </StTitle>
                        </Spacing>
                        <StSectionInfo>
                            <ImageTextGrid
                                split4060
                                styleSmall
                                color={page.highlightColor}
                                items={page.section2Stylist.map(stylist => {
                                    return {
                                        img: get(
                                            stylist,
                                            'images[0].localFile.publicURL'
                                        ),
                                        text: documentToReactComponents(
                                            JSON.parse(stylist.text.raw || '{}')
                                        ),
                                        title: stylist.titleText,
                                    };
                                })}
                            />
                        </StSectionInfo>
                    </Spacing>
                    <Spacing>
                        <StSectionInfo>
                            <Container>
                                <FlexImageText
                                    styleSmall
                                    color={page.highlightColor}
                                    items={page.section2Text.map(text => {
                                        return {
                                            img: get(
                                                text,
                                                'images[0].localFile.publicURL'
                                            ),
                                            text: documentToReactComponents(
                                                JSON.parse(text.text.raw || '{}')
                                            ),
                                            title: text.titleText,
                                        };
                                    })}
                                />
                            </Container>
                        </StSectionInfo>
                    </Spacing>
                    {page.meetTiles && (
                        <Spacing removeSpaceTop>
                            <Spacing removeSpaceTop>
                                <StTitle color={page.highlightColor}>
                                    <h2>{page.section3Title}</h2>
                                </StTitle>
                            </Spacing>
                            <StSectionInfo>
                                <ImageTextGridImage
                                    imgMaxWidth={'550px'}
                                    color={page.highlightColor}
                                    items={page.meetTiles.map(text => {
                                        return {
                                            image: get(
                                                text,
                                                'images[0].localFile.publicURL'
                                            ),
                                            text: documentToReactComponents(
                                                JSON.parse(text.text.raw || '{}')
                                            ),
                                            title: text.titleText,
                                        };
                                    })}
                                />
                            </StSectionInfo>
                        </Spacing>
                    )}

                    {page.tutorialVideos && (
                        <Spacing>
                            <StSectionMobileViewMore>
                                <Spacing removeSpaceTop removeSpaceBottomMobile>
                                    <StTitle color={page.highlightColor}>
                                        <h2>
                                            {intl.formatMessage({
                                                id: 'kenra-latest-tutorial',
                                            })}
                                        </h2>
                                    </StTitle>
                                </Spacing>
                                <Container>
                                    <VideoRow
                                        center
                                        fullWidth
                                        items={page.tutorialVideos.map(
                                            video => {
                                                let youTubeId = getYouTubeId(
                                                    video.youtubeLink
                                                );
                                                return {
                                                    btnSizeMedium: true,
                                                    bgImg: getPreviewUrl(
                                                        youTubeId
                                                    ),
                                                    videoType: 'youtube',
                                                    autoPlay: true,
                                                    videoOptions: [
                                                        {
                                                            src: getEmbedUrl(
                                                                youTubeId
                                                            ),
                                                        },
                                                    ],
                                                };
                                            }
                                        )}
                                    />
                                </Container>
                            </StSectionMobileViewMore>
                        </Spacing>
                    )}
                </StSectionInfo>
            </Spacing>
            <Spacing>
                <StSectionInfo>
                    <Container>
                        <StSectionBody
                            dangerouslySetInnerHTML={{
                                __html: formatHTML(JSON.parse(page.section5Text.raw)),
                            }}
                        ></StSectionBody>
                        <StLinkMore
                            styleBig
                            rounded
                            largeText
                            color={page.highlightColor}
                        >
                            <CustomLink
                                href={page.section5Url}
                                target={'_blank'}
                            >
                                {intl.formatMessage({
                                    id: 'rapunzel-project-donate',
                                })}
                            </CustomLink>
                        </StLinkMore>
                        <Spacing removeSpaceBottom>
                            <StSectionBody
                                dangerouslySetInnerHTML={{
                                    __html: formatHTML(
                                        JSON.parse(page.section5SubText.raw)
                                    ),
                                }}
                            ></StSectionBody>
                        </Spacing>
                    </Container>
                </StSectionInfo>
            </Spacing>
        </>
    );
};

/*
                            <div style={{display: 'flex'}}>
                                {page.section2Text.map(text => {
                                    return (
                                        <div style={{maxWidth: '50%'}}>
                                        <img style={{maxWidth: '100px' }} src={get(text, 'images[0].localFile.publicURL')} />
                                        <div
                                            style={{padding: '0px 30px' }}
                                            dangerouslySetInnerHTML={{
                                                __html: formatHTML(text.text.json),
                                            }} 
                                        />
                                    </div>                                        
                                    )
                                })}
                            </div>    
                                <ImageTextGrid
                                    items={page.section2Text.map(text => {
                                        return {
                                            img: get(text, 'images[0].localFile.publicURL'),
                                            text: formatHTML(text.text.json)
                                        }
                                    })}
                                />
            <Spacing>
                <StSectionInfo>
                    <Container>
                        <StTitle>
                            <h2>{page.section2Title}</h2>
                        </StTitle>
                        <ImageTextGrid
                            items={page.section2Stylist.map(stylist => {
                                return {
                                    img: get(stylist, 'images[0].localFile.publicURL'),
                                    text: formatHTML(stylist.text.json)
                                }
                            })}
                        />
                    </Container>
                </StSectionInfo>
            </Spacing>

            {page.meetTiles && (
                <Spacing>
                    <StTitle>
                        <h2>{page.section3Title}</h2>
                    </StTitle>
                    <ImageTextTile
                        rowRevers
                        items={page.meetTiles.map(tile => {
                            let images = [];
                            tile.images.forEach(image => {
                                images.push(get(image, 'localFile.publicURL'));
                            });
                            return {
                                images: images,
                                title: tile.title,
                                text: documentToReactComponents(tile.text.json),
                            };
                        })}
                    />
                </Spacing>
            )}
                        <StTitle>
                            <h2>{page.title}</h2>
                        </StTitle>

                        <StLinkMore styleBig color={page.buttonLinkColor}>
                            <CustomLink href={page.buttonLink}>
                                {page.buttonLinkText}
                            </CustomLink>
                        </StLinkMore>

*/
/*

            <Spacing removeSpaceTop removeSpaceBottomMobile>
                <StSectionInfo useMobileBg>
                    <Container>
                        <StBigTitleGradient
                            gradient={page.section2BackgroundGradient}
                            style={{
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                            }}
                        >
                            {page.section1title}
                        </StBigTitleGradient>
                        <StMediumTitle>{page.section1Subtitle}</StMediumTitle>

                        {documentToReactComponents(
                            get(page, 'section1Text.json')
                        )}
                    </Container>
                </StSectionInfo>
            </Spacing>

            <Spacing
                removeSpaceTop
                removeSpaceTopMobile
                removeSpaceBottomMobile
            >
                <Container>
                    <HeroSlider slides={page.banner} />
                </Container>
            </Spacing>

            <Spacing removeSpaceTop>
                <StSectionInfo
                    style={{
                        backgroundImage: `linear-gradient(${page.section2BackgroundGradient})`,
                    }}
                >
                    <Container>
                        <ThrowbackPanel
                            titleImage={get(
                                page,
                                'section2TitleImage.localFile.publicURL'
                            )}
                            image={get(
                                page,
                                'section2Image.localFile.publicURL'
                            )}
                            items={items}
                            petaImage={get(
                                page,
                                'section2Logo.localFile.publicURL'
                            )}
                            buttonUrl={page.section2ButtonUrl}
                            gradient={page.section2BackgroundGradient}
                        />
                    </Container>
                </StSectionInfo>
            </Spacing>

            {page.mostWanted && (
                <Spacing removeSpaceTop>
                    <StSectionInfo useMobileBg>
                        <Container>
                            <StMediumTitleGradient
                                gradient={page.section2BackgroundGradient}
                                style={{
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                }}
                            >
                                {page.mostWantedTitle}
                            </StMediumTitleGradient>
                            <ImageTextGridImage
                                rowRevers
                                items={page.mostWanted.map(tile => {
                                    return {
                                        image: get(
                                            tile,
                                            'image.localFile.publicURL'
                                        ),
                                        text: documentToReactComponents(
                                            tile.text.json
                                        ),
                                        textImage: get(
                                            tile,
                                            'reviewImage.localFile.publicURL'
                                        ),
                                    };
                                })}
                            />
                        </Container>
                    </StSectionInfo>
                </Spacing>
            )}
*/

/*

            <Spacing removeSpaceTop removeSpaceBottom>
                <StSectionInfo
                    style={{
                        backgroundColor: page.spotifyBackgroundColor,
                    }}
                >
                    <Container>
                        <SpotifyPanel
                            title={page.spotifyTitle}
                            image={get(
                                page,
                                'spotifyImage.localFile.publicURL'
                            )}
                            buttonUrl={page.spotifyUrl}
                            buttonImage={get(
                                page,
                                'spotifyButtonImage.localFile.publicURL'
                            )}
                        />
                    </Container>
                </StSectionInfo>
            </Spacing>
*/
export default injectIntl(RapunzelLanding);
